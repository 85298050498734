<template>
  <div class="single-prediciton">
    <APinput></APinput>
  </div>
</template>

<script>
// import APintroduction from "../components/apintroduction.vue";
import APinput from "../components/apinput.vue";
// import Header from "../components/header.vue";

export default {
  name: "single-prediction",
  data() {
    return {};
  },
  components: {
    APinput,
  },
  methods: {},
};
</script>



<style scoped>
</style>
